import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, image, titleTemplate, children }) => {
  const { pathname } = useLocation();
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            titleTemplate
            title
            description
            image
            url
            twitterUsername
          }
        }
      }
    `
  );

  const {
    titleTemplate: defaultTitleTemplate,
    url: siteUrl,
    twitterUsername,
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
  } = data.site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet titleTemplate={titleTemplate || defaultTitleTemplate} defaultTitle={defaultTitle}>
      <html lang="en" />

      <title>{seo.title}</title>

      <meta charSet="utf-8" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.title} />
      <meta property="og:url" content={seo.url} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.title} />

      {children}
    </Helmet>
  );
};

export default Meta;
