import React from 'react';

const Icon = (props) => {
  switch (props.name) {
    case 'js':
      return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="currentcolor" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.690262 0.666748H59.8761V59.3334H0.690262V0.666748ZM16.2525 49.6928C17.5635 52.4491 20.1463 54.7331 24.5954 54.7331C29.522 54.7331 32.8988 52.134 32.8988 46.4238V27.6004H27.3368V46.3458C27.3368 49.1022 26.1846 49.8109 24.357 49.8109C22.4503 49.8109 21.6555 48.5115 20.7817 46.9759L16.2525 49.6928ZM40.4487 46.503L35.92 49.1021C37.5487 52.2921 40.886 54.7336 46.0508 54.7336C51.3347 54.7336 55.2678 52.0165 55.2678 47.0543C55.2678 42.4469 52.6058 40.3993 47.878 38.3908L46.4876 37.8001C44.1041 36.7762 43.0711 36.1065 43.0711 34.4525C43.0711 33.1138 44.1041 32.0899 45.733 32.0899C47.3221 32.0899 48.3548 32.7593 49.3083 34.4525L53.6389 31.696C51.8115 28.5061 49.2689 27.2852 45.733 27.2852C40.7666 27.2852 37.5883 30.4358 37.5883 34.5708C37.5883 39.0603 40.2503 41.1868 44.2632 42.8798L45.6533 43.471C48.196 44.5732 49.7056 45.2428 49.7056 47.1331C49.7056 48.7083 48.236 49.8504 45.9315 49.8504C43.1903 49.8504 41.6409 48.4329 40.4487 46.503Z"
          />
        </svg>
      );

    case 'browser':
      return (
        <svg width="65" height="62" viewBox="0 0 65 62" fill="currentcolor" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.39 0H0.899994V14.47H64.39V0ZM10.35 11C11.3717 11 12.2 10.1718 12.2 9.15005C12.2 8.12832 11.3717 7.30005 10.35 7.30005C9.32827 7.30005 8.5 8.12832 8.5 9.15005C8.5 10.1718 9.32827 11 10.35 11ZM19.9 9.15005C19.9 10.1718 19.0717 11 18.05 11C17.0283 11 16.2 10.1718 16.2 9.15005C16.2 8.12832 17.0283 7.30005 18.05 7.30005C19.0717 7.30005 19.9 8.12832 19.9 9.15005ZM25.75 11C26.7717 11 27.6 10.1718 27.6 9.15005C27.6 8.12832 26.7717 7.30005 25.75 7.30005C24.7283 7.30005 23.9 8.12832 23.9 9.15005C23.9 10.1718 24.7283 11 25.75 11Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.49 18H1V61.41H64.49V18ZM37.5317 24.0293L24.7629 50.6743L27.4683 51.9708L40.2371 25.3258L37.5317 24.0293ZM21.563 28.8287L23.437 31.1713L14.9012 38L23.437 44.8287L21.563 47.1713L10.0988 38L21.563 28.8287ZM43.937 28.8287L55.4012 38L43.937 47.1713L42.063 44.8287L50.5988 38L42.063 31.1713L43.937 28.8287Z"
          />
        </svg>
      );

    case 'a11y':
      return (
        <svg width="512" height="512" viewBox="0 0 512 512" fill="currentcolor" {...props}>
          <circle cx="256" cy="256" r="256" fill="#ff4400" />
          <ellipse cx="255.557" cy="123.462" rx="35.4754" ry="35.4617" fill="white" />
          <path
            d="M411 177H101V212.462H211.212L211.212 424H246.688V308.749H264.425V424H299.901L299.901 212.462H411V177Z"
            fill="white"
          />
        </svg>
      );

    case 'pin':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            d="M12.2432 2C8.2493 2 5 5.2493 5 9.24321C5 14.1998 11.482 21.4763 11.758 21.7837C12.0172 22.0724 12.4698 22.0719 12.7285 21.7837C13.0045 21.4763 19.4865 14.1998 19.4865 9.24321C19.4864 5.2493 16.2372 2 12.2432 2ZM12.2432 12.8875C10.2338 12.8875 8.59903 11.2527 8.59903 9.24321C8.59903 7.23376 10.2338 5.59899 12.2432 5.59899C14.2527 5.59899 15.8874 7.2338 15.8874 9.24325C15.8874 11.2527 14.2527 12.8875 12.2432 12.8875Z"
            fill="currentcolor"
          />
        </svg>
      );

    case 'calendar':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.625 4.26415C14.625 4.13385 14.6414 4.0074 14.6723 3.88679H9.32774C9.35859 4.0074 9.375 4.13385 9.375 4.26415V5.01887C9.375 5.85251 8.70343 6.5283 7.875 6.5283H7.125C6.29657 6.5283 5.625 5.85251 5.625 5.01887V4.26415C5.625 4.13385 5.64141 4.0074 5.67226 3.88679H4.5C3.67157 3.88679 3 4.56259 3 5.39623V20.4906C3 21.3242 3.67157 22 4.5 22H19.5C20.3284 22 21 21.3242 21 20.4906V5.39623C21 4.56259 20.3284 3.88679 19.5 3.88679H18.3277C18.3586 4.0074 18.375 4.13385 18.375 4.26415V5.01887C18.375 5.85251 17.7034 6.5283 16.875 6.5283H16.125C15.2966 6.5283 14.625 5.85251 14.625 5.01887V4.26415ZM5.25 8.03774C4.83579 8.03774 4.5 8.37563 4.5 8.79245V19.3585C4.5 19.7753 4.83579 20.1132 5.25 20.1132H18.75C19.1642 20.1132 19.5 19.7753 19.5 19.3585V8.79245C19.5 8.37563 19.1642 8.03774 18.75 8.03774H5.25Z"
          />
          <rect x="6.375" y="2" width="2.25" height="3.77358" rx="0.9" />
          <rect x="15.3722" y="2" width="2.25" height="3.77358" rx="0.9" />
          <rect x="6.375" y="9.54717" width="2.25" height="2.26415" rx="0.9" />
          <rect x="6.375" y="16.3396" width="2.25" height="2.26415" rx="0.9" />
          <rect x="6.375" y="12.9434" width="2.25" height="2.26415" rx="0.9" />
          <rect x="15.375" y="16.3396" width="2.25" height="2.26415" rx="0.9" />
          <rect x="15.375" y="12.9434" width="2.25" height="2.26415" rx="0.9" />
          <rect x="10.875" y="16.3396" width="2.25" height="2.26415" rx="0.9" />
          <rect x="10.875" y="12.9434" width="2.25" height="2.26415" rx="0.9" />
          <rect x="10.875" y="9.54717" width="2.25" height="2.26415" rx="0.9" />
          <rect x="15.375" y="9.54717" width="2.25" height="2.26415" rx="0.9" />
        </svg>
      );

    case 'slides':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <g clipPath="url(#clip0)">
            <path d="M12.0005 10.9846C11.4611 10.9846 11.0239 10.5474 11.0239 10.008C11.0239 9.46866 11.4611 9.03142 12.0005 9.03142C12.5398 9.03142 12.9771 9.46866 12.9771 10.008C12.9771 10.5474 12.5398 10.9846 12.0005 10.9846Z" />
            <path d="M20.2428 16.6488C20.2428 17.0803 19.893 17.4301 19.4616 17.4301L12.5864 17.4301L12.5864 19.0452L19.1863 20.8494C19.4985 20.9347 19.6824 21.257 19.5971 21.5691C19.5259 21.8295 19.2898 22.0007 19.0322 22.0007C18.981 22.0007 18.929 21.9939 18.8774 21.9798L12.029 20.1077L5.12234 21.9801C4.80983 22.0647 4.48814 21.8802 4.40349 21.5679C4.31884 21.2555 4.50338 20.9337 4.81573 20.8491L11.4145 19.0601L11.4145 17.4301L4.53936 17.4301C4.10786 17.4301 3.75809 17.0803 3.75809 16.6488L3.75809 6.29701L20.2429 6.29701L20.2429 16.6488L20.2428 16.6488ZM15.3778 14.0688L15.0149 12.6133C14.8507 11.955 14.3952 11.433 13.8059 11.1704C14.0226 10.835 14.1489 10.4361 14.1489 10.008C14.1489 8.82336 13.1851 7.85955 12.0004 7.85955C10.8158 7.85955 9.85194 8.82336 9.85194 10.008C9.85194 10.4361 9.97828 10.835 10.1949 11.1704C9.60565 11.433 9.15009 11.955 8.98599 12.6134L8.62309 14.0688C8.54988 14.3625 8.61473 14.6678 8.80114 14.9063C8.98751 15.1448 9.26799 15.2816 9.57069 15.2816L14.4302 15.2816C14.7329 15.2816 15.0134 15.1448 15.1997 14.9063C15.3861 14.6678 15.451 14.3625 15.3778 14.0688Z" />
            <path d="M10.1231 12.8968C10.2318 12.4609 10.6214 12.1565 11.0706 12.1565L12.9302 12.1565C13.3795 12.1565 13.7692 12.4609 13.8778 12.8968L14.1802 14.1097L9.82065 14.1097L10.1231 12.8968Z" />
            <path d="M2.78127 2L21.2192 2C21.6507 2 22.0005 2.34978 22.0005 2.78127L22.0005 4.34381C22.0005 4.77531 21.6507 5.12508 21.2192 5.12508L2.78127 5.12508C2.34978 5.12508 2.00001 4.7753 2.00001 4.34381L2.00001 2.78127C2.00001 2.34978 2.34978 2 2.78127 2Z" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 24 1.90735e-06)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    case 'film':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path d="M15.5579 6H3.45833C2.65321 6.00095 2.00095 6.65321 2 7.45833V17.0552C2.00095 17.8604 2.65321 18.5126 3.45833 18.5136H15.5579C16.3629 18.5126 17.0153 17.8604 17.0163 17.0552V7.45833C17.0153 6.65321 16.3629 6.00095 15.5579 6Z" />
          <path d="M17.9885 14.2542L23 16.9903V7.5546L17.9885 10.2907V14.2542Z" />
        </svg>
      );

    case 'email':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path d="M20.2422 4H3.75781C2.78977 4 2 4.7891 2 5.75824V17.4799C2 18.4457 2.78625 19.2381 3.75781 19.2381H20.2422C21.2078 19.2381 22 18.4517 22 17.4799V5.75824C22 4.79238 21.2138 4 20.2422 4ZM19.9995 5.17216L12.0373 13.1363L4.00621 5.17216H19.9995ZM3.17188 17.2371V5.99537L8.81559 11.592L3.17188 17.2371ZM4.00051 18.0659L9.64777 12.4173L11.6266 14.3796C11.8556 14.6067 12.2253 14.606 12.4534 14.3778L14.3828 12.4479L19.9995 18.0659H4.00051ZM20.8281 17.2371L15.2114 11.619L20.8281 6.00096V17.2371Z" />
        </svg>
      );

    case 'linkedin':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path d="M20 2H4C2.9 2 2 2.9 2 4V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V4C22 2.9 21.1 2 20 2ZM8 19H5V10H8V19ZM6.5 8.3C5.5 8.3 4.7 7.5 4.7 6.5C4.7 5.5 5.5 4.7 6.5 4.7C7.5 4.7 8.3 5.5 8.3 6.5C8.3 7.5 7.5 8.3 6.5 8.3ZM19 19H16V13.7C16 12.9 15.3 12.2 14.5 12.2C13.7 12.2 13 12.9 13 13.7V19H10V10H13V11.2C13.5 10.4 14.6 9.8 15.5 9.8C17.4 9.8 19 11.4 19 13.3V19Z" />
        </svg>
      );

    case 'github':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path d="M12.0001 2C6.50748 2 2 6.50745 2 12C2 16.687 5.2889 20.9447 9.65631 22V18.6916C9.22357 18.7863 8.82531 18.7891 8.38478 18.6592C7.79381 18.4848 7.31361 18.0912 6.95716 17.4909C6.72996 17.1076 6.32727 16.6919 5.9072 16.7224L5.8042 15.5551C6.71256 15.4773 7.49839 16.1086 7.96486 16.8923C8.17207 17.2408 8.41057 17.4451 8.71666 17.5354C9.01254 17.6226 9.33023 17.5807 9.70041 17.45C9.79333 16.7089 10.1328 16.4315 10.3893 16.0408V16.0402C7.78465 15.6517 6.74659 14.2699 6.3346 13.1792C5.78863 11.731 6.0816 9.92175 7.04704 8.77841C7.0658 8.75613 7.09968 8.69785 7.08656 8.6571C6.6439 7.31998 7.1833 6.21387 7.20313 6.09653C7.714 6.24759 7.79701 5.94455 9.42224 6.93195L9.70315 7.10071C9.82065 7.17075 9.78372 7.13077 9.90121 7.12192C10.5798 6.93759 11.2951 6.83582 11.9999 6.82666C12.7101 6.83582 13.4208 6.93759 14.1275 7.12939L14.2184 7.13855C14.2105 7.13733 14.2431 7.13275 14.2979 7.1001C16.3281 5.87039 16.2552 6.27231 16.7996 6.09531C16.8193 6.2128 17.3515 7.33691 16.9136 8.6571C16.8545 8.83899 18.6737 10.5048 17.6654 13.1787C17.2534 14.2699 16.2155 15.6517 13.6108 16.0402V16.0408C13.9447 16.5497 14.3458 16.8204 14.3437 17.8702V22C18.7112 20.9447 22 16.687 22 12C22.0002 6.50745 17.4927 2 12.0001 2V2Z" />
        </svg>
      );

    case 'folder':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path d="M21.9866 9.70288L20.3687 17.9231C20.2453 18.5498 19.7136 19 19.0966 19H4.03914C3.21777 19 2.60288 18.2192 2.76705 17.385L4.21523 10.0274C4.34547 9.36583 4.90675 8.89072 5.55799 8.89072H20.7173H21.347C21.6568 8.89072 21.9082 9.11158 21.9796 9.39902C22.0034 9.49479 22.0072 9.5979 21.9866 9.70288ZM3.22595 9.81813C3.4511 8.67426 4.4318 7.84398 5.55799 7.84398H20.7108C20.6502 7.24598 20.1611 6.7783 19.5684 6.7783H11.5736C11.4116 5.7705 10.5645 5 9.54722 5H3.34792C2.60469 4.9999 2 5.62679 2 6.39729V16.0467L3.22595 9.81813Z" />
        </svg>
      );

    case 'tag':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <path d="M21.2857 2H13.4287C13.2392 2.00004 13.0576 2.07533 12.9237 2.2093L2.2095 12.9233C1.93038 13.2021 1.93013 13.6543 2.20895 13.9334C2.20912 13.9336 2.20933 13.9338 2.2095 13.934L10.0665 21.7909C10.3455 22.0697 10.7976 22.0697 11.0765 21.7909L21.7907 11.0769C21.9248 10.9428 22.0001 10.7609 22 10.5712V2.71428C22 2.31979 21.6802 2 21.2857 2ZM17 8.4284C16.2111 8.4284 15.5715 7.78882 15.5715 6.99988C15.5715 6.21094 16.2111 5.57136 17 5.57136C17.789 5.57136 18.4286 6.21094 18.4286 6.99988C18.4286 7.78882 17.789 8.4284 17 8.4284Z" />
        </svg>
      );

    case 'edit':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
          <rect x="4" y="20.1818" width="16.5211" height="1.81818" />
          <path d="M14.4971 4.57149L6.28317 12.7075L9.70532 16.097L17.9196 7.96138L14.4971 4.57149Z" />
          <path d="M20.3797 4.84692L17.6415 2.13483C17.46 1.95506 17.1386 1.95506 16.9571 2.13483L15.1815 3.89357L18.604 7.28347L20.3797 5.52483C20.4704 5.43492 20.5215 5.31322 20.5215 5.18589C20.5215 5.05856 20.4704 4.93682 20.3797 4.84692Z" />
          <path d="M5.59836 13.3854L5.25591 13.7246C5.19824 13.7817 5.15571 13.8524 5.133 13.9301L4.01892 17.7452C3.97023 17.9132 4.01702 18.0939 4.14183 18.2175C4.23399 18.3088 4.35738 18.358 4.48403 18.358C4.52895 18.358 4.57432 18.3519 4.61875 18.3393L8.47054 17.2358C8.54902 17.2133 8.62039 17.1712 8.67806 17.1141L9.02051 16.7749L5.59836 13.3854Z" />
        </svg>
      );

    default:
      return null;
  }
};

export default Icon;
